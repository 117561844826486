<template>
  <Banner
    theme="orange"
    icon="information-outline"
    class="tw-mt-4 tw-mx-4"
    :closable="false"
  >
    <p class="tw-align-top">
      {{ warningMessage }}
    </p>

    <div class="tw-mt-2">
      <button
        v-if="activeEmployment.is_admin"
        class="tw-px-5 tw-py-1 tw-rounded-full tw-font-semibold tw-text-sm tw-text-orange-100 hover:tw-text-white tw-bg-orange-500 tw-cursor-pointer"
        @click="navigateToCalendarsPage()"
      >
        Go To Calendars
      </button>
    </div>
  </Banner>
</template>

<script>
import Banner from '@/components/Banner'
import pluralize from 'pluralize'

export default {
  name: 'UnavailableAllowanceYears',

  components: { Banner },

  props: {
    warnings: {
      type: Object,
      required: true,
    },
  },

  computed: {
    warningMessage() {
      let unavailableAllowanceYearsText = this.unavailableAllowanceYearsText()

      if (this.activeEmployment.isAdmin()) {
        if (this.warnings.code === 'unavailable_company_allowance_years') {
          return this.unavailableCompanyAllowanceYearsMessage(
            unavailableAllowanceYearsText
          )
        }

        if (this.warnings.unavailable_allowance_years.length === 2) {
          unavailableAllowanceYearsText = this.formatText()
        }

        return this.unavailableAlignedAllowanceYearsMessage(
          unavailableAllowanceYearsText
        )
      }

      return this.unavailableAllowanceYearsWarningMessageForNonAdmins()
    },
  },

  methods: {
    navigateToCalendarsPage() {
      this.$router.push({ name: 'calendars' }, () => {})
    },

    unavailableAllowanceYearsText() {
      const listFormatter = new Intl.ListFormat('en', { style: 'short' })

      return (
        listFormatter.format(this.warnings.unavailable_allowance_years) +
        ' ' +
        pluralize('calendar', this.warnings.unavailable_allowance_years.length)
      )
    },

    formatText() {
      return (
        ' both ' +
        this.warnings.unavailable_allowance_years[0] +
        ' and ' +
        this.warnings.unavailable_allowance_years[1] +
        ' calendar '
      )
    },

    unavailableAlignedAllowanceYearsMessage(unavailableAllowanceYearsText) {
      return `Oops! Leave can't be booked for the current year. When having employees with an aligned leave year,
      you need to have ${unavailableAllowanceYearsText} available for the system to be able to allocate
      their offset calendar. Head to Settings > Calendars to create or unhide them, and you'll be all set!`
    },

    unavailableCompanyAllowanceYearsMessage(unavailableAllowanceYearsText) {
      return `Oops! Leave can't be booked for the current year. The ${unavailableAllowanceYearsText} isn't set up.
      Head to Settings > Calendars to create or unhide it, and you'll be all set!`
    },

    unavailableAllowanceYearsWarningMessageForNonAdmins() {
      return `Oops! You can't book any leave for the current year.
      Contact your admin to set up the required calendar.`
    },
  },
}
</script>
