import Company from '@/models/company/Company'
import WorkingSchedule from '@/components/working-schedule/WorkingSchedule'

export default class Employment {
  constructor(rawEmployment, company = null) {
    Object.keys(rawEmployment).forEach(property => {
      this[property] = rawEmployment[property]
    })

    this.company = new Company(company ?? rawEmployment?.company)
  }

  get availableWorkingSchedule() {
    return this.available_working_schedule
      ? new WorkingSchedule(this.available_working_schedule)
      : this.company.activeWorkingSchedule
  }

  get minutesPerWorkingDay() {
    return this.minutes_per_working_day ?? this.company.minutesPerWorkingDay
  }

  get allowanceUnitIsDays() {
    return this.allowance_unit_is_days ?? this.company.allowanceUnitIsDays
  }

  get allowanceUnit() {
    return this.allowanceUnitIsDays ? 'days' : 'hours'
  }

  getKey() {
    return this.id
  }

  get superiorNames() {
    const lf = new Intl.ListFormat('en', { style: 'short' })
    return lf.format(this.superiors.map(s => s.full_name))
  }

  hasPermissionsToSeeMoreDetails(employee) {
    return (
      this.canViewAllowanceYear(employee) ||
      this.isFollowerOf(employee.getKey())
    )
  }

  isAdmin() {
    return this.is_admin
  }

  isApprover() {
    return this.is_approver
  }

  isApproverOf(employmentKey) {
    return (
      !!this.subordinates &&
      this.subordinates.some(subordinate => {
        return subordinate.id === employmentKey
      })
    )
  }

  isFollowerOf(employmentKey) {
    return this.followings.some(following => {
      return following.id === employmentKey
    })
  }

  canViewAllowanceYear(employee) {
    return (
      this.isAdmin() ||
      this.isApproverOf(employee.getKey()) ||
      this.isSame(employee)
    )
  }

  isSame(employee) {
    return employee instanceof Employment && this.getKey() === employee.getKey()
  }

  getCompanyScheduleFilter() {
    const filter = this.settings.company_schedule.filter
    const order = this.settings.company_schedule.order

    if (!order && !filter) {
      return 'everyone'
    }

    if (!order && filter.department === null) {
      return 'departments_missing'
    }

    if (!order && filter.department !== null) {
      return filter.department
    }

    if (!filter && order[0] === 'department') {
      return 'group_by_department'
    }
  }

  get hasWarnings() {
    return Object.keys(this.warnings).length !== 0
  }
}
